<template>
  <div id="principal" class="shadow-sm h-100 d-block">
    <div class="card-panel identificacao pt-1">
      <div class="row">
        <div class="col-7 col-md-4">
          <div class="d-flex justify-content-center">
            <button
              id="lmxta-identificacao-btn-cliente"
              type="button"
              data-toggle="modal"
              data-target="#ModalPesquisaCliente"
              class="btn btn-secondary text-center identificacao-cliente-button"
              @click="abrirSelecaoFornecedor()"
              data-backdrop="static"
              data-keyboard="false"
            >
              <div class="truncate" v-if="!Identificacao.Fornecedor">Informe o fornecedor</div>
              <div class="truncate">{{ nomeFornecedor }}</div>
            </button>
          </div>
        </div>
      </div>
    </div>

    <FornecedorSelecao
      v-if="UI.SelecionandoFornecedor"
      @fechar="UI.SelecionandoFornecedor = false"
      @selecionar="selecionarFornecedor"
    />

    <div class="card-panel d-flex flex-column justify-content-between identificacao-content pt-0">
      <div class="d-flex flex-column justify-content-center align-items-center h-100">
        <div class="row">
          <div class="col-12">
            <div class="pb-0 mx-auto text-center">
              <p class="lead mb-0"
                >Para que possamos lhe oferecer a melhor experiência, selecione o formato de devolução que melhor lhe atende.</p
              >
              <p class="text-muted"
                >Não se preocupe, independente da opção selecionada você conseguirá devolver tudo que precisar.</p
              >
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-6 d-flex align-items-stretch">
            <div class="card d-flex flex-column justify-content-center align-items-center" style="max-width: 30rem">
              <img src="@/assets/Images/funcionais55.png" class="card-img" style="width: 6rem" />
              <div class="card-body pt-0 pb-0">
                <p class="card-text"
                  >Informar apenas os itens e quero que o sistema me ajude a identificar as notas de entrada origem.</p
                >
                <p class="card-text-info card-text text-muted">
                  Recomendado quando não se sabe a que notas de entrada os itens que irá devolver pertencem.<br />
                  Podemos sugerir notas de entrada automaticamente, mas se desejar alterar a nota, você também terá essa opção.
                </p>
              </div>
              <div class="card-footer border-0 bg-white">
                <button id="button-devolver-por-itens" class="btn btn-primary" @click="redirecionar(DEVOLUCAO_TIPO.PorItem)"
                  >Devolver a partir dos produtos</button
                >
              </div>
            </div>
          </div>

          <div class="col-6 d-flex align-items-stretch">
            <div class="card d-flex flex-column justify-content-center align-items-center" style="max-width: 30rem">
              <img src="@/assets/Images/funcionais54.png" class="card-img" style="width: 6rem" />
              <div class="card-body pt-0 pb-0">
                <p class="card-text">Informar uma ou mais notas de entrada que são do meu conhecimento.</p>
                <p class="card-text-info card-text text-muted">
                  Recomendado quando se sabe o número/série ou chave NF-e ou quando se tem o XML NF-e.<br />Você pode devolver a
                  nota inteira ou somente alguns dos seus itens.
                </p>
              </div>
              <div class="card-footer border-0 bg-white">
                <button id="button-devolver-por-notas" class="btn btn-primary" @click="redirecionar(DEVOLUCAO_TIPO.PorNota)"
                  >Devolver a partir das notas de entrada</button
                >
              </div>
            </div>
          </div>
        </div>
        <div class="row pt-2">
          <div class="col font-italic text-center">
            <p class="text-muted"
              >Apenas os produtos cuja a nota de entrada foi lançada pelas rotinas de entrada via XML poderão ser devolvidas por
              esta solução.</p
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import swal from '@/common/alerts';
  import loading from '@/common/loading';
  import FornecedorSelecao from '@/components/shared/crm/FornecedorSelecao.vue';
  import { DEVOLUCAO_TIPO } from '@/core/enums/devolucao-tipo.enum';

  export default {
    name: 'Identificacao',

    components: {
      FornecedorSelecao,
    },

    data() {
      return {
        DEVOLUCAO_TIPO,
        UI: {
          SelecionandoFornecedor: false,
        },
      };
    },

    computed: {
      Identificacao() {
        return this.$store.state.devolucao.Identificacao;
      },

      nomeFornecedor() {
        if (this.Identificacao.Fornecedor) {
          return this.Identificacao.Fornecedor.Nome;
        }

        return '';
      },
    },

    methods: {
      redirecionar(tipoDevolucao) {
        if (!this.Identificacao.Fornecedor) {
          this.abrirSelecaoFornecedor();
          return;
        }

        this.$store.dispatch('devolucao/atualizarTipoDevolucao', tipoDevolucao);
        this.$router.push({ name: 'DevolucaoItens' });
      },

      abrirSelecaoFornecedor() {
        this.UI.SelecionandoFornecedor = true;
      },

      selecionarFornecedor(fornecedor) {
        this.$store.dispatch('devolucao/atualizarFornecedor', fornecedor);
      },

      acessarConfiguracao() {
        this.$router.push({ name: 'DevolucaoConfiguracoes' });
      },
    },
  };
</script>

<style scoped>
  .only-overflow-y {
    overflow-y: auto;
    overflow-x: hidden;
  }
  .identificacao-cliente-button {
    width: 100%;
  }

  @media screen and (max-height: 650px) {
    .lead {
      font-size: 1.15rem;
    }

    .card-text {
      margin-bottom: 0.5rem !important;
    }

    .card-img {
      width: 5rem !important;
    }

    .card-text-info {
      font-size: 0.9rem;
    }
  }

  @media screen and (max-width: 1000px) {
    .btn {
      font-size: 13px;
    }
  }
</style>
